// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   URL_SERVICES: "https://apisandbox.bottletracking.com/api",
   URL_MONITOR: 'https://apisandbox.bottletracking.com/api/statistics',
};

